import { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { FetchInvoicesCacheKeys, InvoiceID } from '../../../invoicesTypes';

import { useCancelInvoiceSmartContracts } from '../../../hooks/useCancelInvoiceSmartContracts';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import { InvoicesPermissions } from '../../../invoicesConstants';

import { invoicesKeys, words } from '../../../../../locales/keys';

interface CancelInvoiceSmartContractsModalButtonProps {
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  invoiceId: InvoiceID;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}
function CancelInvoiceSmartContractsModalButton({
  cacheKeys,
  className,
  disabled,
  icon,
  iconClassName,
  invoiceId,
  i18nText,
  tooltipI18nText
}: CancelInvoiceSmartContractsModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    cancelInvoiceSmartContracts,
    cancelInvoiceSmartContractsErrorMessage,
    cancelInvoiceSmartContractsLoading
  } = useCancelInvoiceSmartContracts({ cacheKeys });

  const handleCancelInvoiceSmartContracts = useCallback(
    () =>
      cancelInvoiceSmartContracts({
        invoiceId,
        async: currentUser.hasPermissions(
          InvoicesPermissions.READ_CANCEL_SMART_CONTRACTS_AS_ASYNC
        )
      }),
    [cancelInvoiceSmartContracts, currentUser, invoiceId]
  );

  return (
    <ConfirmModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      disabled={cancelInvoiceSmartContractsLoading || disabled}
      errorMessage={cancelInvoiceSmartContractsErrorMessage}
      i18nButtonText={i18nText}
      i18nSubmitText={words.yes}
      i18nCancelText={words.no}
      i18nText={
        invoicesKeys.allSmartContractsContainingItemsFromThisInvoiceWillBeCanceledAreYouSureYouWantToCancelSmartContracts
      }
      i18nTitle={invoicesKeys.cancelSmartContracts}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={cancelInvoiceSmartContractsLoading}
      modalIcon={IconsEnum.EXCLAMATION}
      modalIconClassName="h-6 w-6 text-red-600"
      onSubmit={handleCancelInvoiceSmartContracts}
    />
  );
}

export default CancelInvoiceSmartContractsModalButton;
